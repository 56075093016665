import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
//import { map } from 'rxjs/operators';
//import { Subject, Observable, BehaviorSubject } from 'rxjs';
import { environment } from '../../environments/environment';


@Injectable({
  providedIn: 'root'
})
export class ProductDetailsService {
  protected baseURL = environment.baseURL;
  constructor(private http: HttpClient) { }

  getProductData(slug,userId) {
    var url=this.baseURL +'product/'+slug+'?token='+(userId?btoa(userId):'');

    if(localStorage.getItem("postal_code")!=undefined  && localStorage.getItem("postal_code")!='')
    {
      url = url+'&postal_code='+localStorage.getItem("postal_code");
    }

    let promise = new Promise((resolve, reject) => {

      this.http.get(url)
        .toPromise()
        .then(
          res => { // Success
            resolve(res);
          },
          msg => { // Error
            reject(msg);
          }
        )
    });
    return promise;
  }
/// Recently view
  getRecenlyViewData(slug,userId) {
    var url=this.baseURL +"recently-view?product_slug=" + slug + "&user_id="+ userId;
    let promise = new Promise((resolve, reject) => {
      this.http.get(url)
        .toPromise()
        .then(
          res => { // Success
            resolve(res);
          },
          msg => { // Error
            reject(msg);
          }
        )
    });
    return promise;
  }

  /// Recently view
  getRecenlyViewDataList(userId) {
    var url=this.baseURL +"get-recently-view-data?user_id="+ userId+'?version='+Math.random();
    let promise = new Promise((resolve, reject) => {
      this.http.get(url)
        .toPromise()
        .then(
          res => { // Success
            resolve(res);
          },
          msg => { // Error
            reject(msg);
          }
        )
    });
    return promise;
  }

}
