import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class RecipesService {

  protected baseURL = environment.baseURL;
  constructor(private http: HttpClient) { }

  getRecipesData() {
    var url=this.baseURL +'get-recipe-lists';
    let promise = new Promise((resolve, reject) => {
      this.http.get(url)
        .toPromise()
        .then(
          res => { // Success
            resolve(res);
          },
          msg => { // Error
            reject(msg);
          }
        )
    });
    return promise;
  }

  getRecipeDetailsData(id)
  {
    var url=this.baseURL +'get-recipe-detail/'+id;
    let promise = new Promise((resolve, reject) => {
      this.http.get(url)
        .toPromise()
        .then(
          res => { // Success
            resolve(res);
          },
          msg => { // Error
            reject(msg);
          }
        )
    });
    return promise;
  }
}
